import React from 'react'
import { PageProps, Link } from 'gatsby'
import Layout from '../../components/Layout'
import { Title, Paragraph } from '../../components/Typography'
import { Link as MuiLink, makeStyles } from '@material-ui/core'
import FormText from '../../components/FormText'
import Form from '../../components/Form'

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: theme.spacing(2),
  },
}))

const Contact: React.FC<PageProps> = () => {
  const classes = useStyles()
  return (
    <Layout path="Contact">
      <Title>Contact</Title>
      <Paragraph>
        お問い合わせはこちらのフォームよりお願いいたします。
      </Paragraph>
      <Paragraph>
        チケットの取り置きは、
        <MuiLink component={Link} to="/contact/torioki/">
          取り置きフォーム
        </MuiLink>
        をご利用ください。
      </Paragraph>
      <Form name="Contact" action="/contact/success/" className={classes.form}>
        <FormText name="name" label="お名前" required />
        <FormText name="email" label="メールアドレス" type="email" required />
        <FormText name="subject" label="件名" />
        <FormText name="body" label="メッセージ本文" multiline rows={10} />
      </Form>
    </Layout>
  )
}

export default Contact
